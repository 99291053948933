<!-- ResidentialService.vue -->
<!-- Presents a list of the residential serviceOptions availble-->

<template>

  <!-- ********************************************** -->
  <!-- HEADER SECTION -->
  <!-- ********************************************** -->
  <v-row>
    <v-col>
      <v-row>

        <!-- Title -->
        <v-col :offset="$vuetify.breakpoint.xs ? 0 : 1" :cols="$vuetify.breakpoint.xs ? 12 : 10">
          <v-card-text primary-title class="text-title mb-0 px-0" :class="headerstore ? 'text-left' : 'text-center'">
            {{ i18n.t("chooseYourResidentialService") }}
          </v-card-text>
        </v-col>
      </v-row>

      <!-- "Back" Button -->
      <v-col class="pa-0 min-height-44 d-flex align-center" offset="1" cols="10">
        <BackBtn></BackBtn>
        <icon class="flex-grow-1" svg="step-marker-2" />
      </v-col>

      <!-- Some Alert -->
      <v-col class="pa-0 d-flex align-center" offset="1" cols="10">
        <AlertBox></AlertBox>
      </v-col>




      <!-- ********************************************** -->
      <!-- Residential serviceOptions Button -->
      <!-- ********************************************** -->

      <v-form ref="wasteForm" v-model="formValid" lazy-validation>

        <!-- Carousel to display all serviceOptions available -->
        <v-carousel height="100%" :hide-delimiters="true" hide-delimiter-background :show-arrows="false"
          :continuous="false">

          <v-col offset="1" cols="10" class="px-0">
            <!-- revoir le nom slideshow -->
            <v-carousel-item v-for="(slide, i) in slideshow" :key="i">
              <v-list>

                <v-list-item-group transition="scroll-x-transition" v-model="form.menuNavigationId"
                  @change="onChangeServiceList()">

                  <v-list-item v-slot="{ active }" class="green-default-svg rounded-lg my-4 py-4"
                    v-for="(service, i) in slide" :key="i" :value="service.name" height="85px" v-bind:disabled="loading"
                    v-ripple="loading ? false : true" active-class="green-active-svg" @click="form.categoryType = service.text; form.svgCategorySelected = service.icon;">

                    <!-- Icons -->
                    <icon v-if="!active" contain width="50px" max-height="51px" :svg="service.icon"
                      class="green-svg ml-4" />
                    <icon v-if="active" contain width="50px" max-height="51px" :svg="service.icon"
                      class="white-svg ml-4" />



                    <!-- ********************************************** -->
                    <!-- Content in Service Button -->
                    <!-- ********************************************** -->
                    <v-list-item-content>

                      <!-- Text with traduction -->
                      <v-list-item-title class="text-h6 pl-6 pr-3" :class="active ? 'white--text' : 'black--text'">
                        <span>{{ service.displayText }}</span>
                        <!-- Icon -->
                        <v-icon v-if="service.name === 'ResiWastePickUp' && containServicePackgesOf('residential')" right color="secondary">mdi-open-in-new</v-icon></v-list-item-title>
                    </v-list-item-content>

                    <!-- Loading -->
                    <icon v-if="active" contain max-width="76px" max-height="71px" svg="checkmark"></icon>
                    <v-fade-transition>
                      <v-overlay v-if="service.name === 'ResiWastePickUp' && loadingWithService" absolute
                        opacity="0.16">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                      </v-overlay>
                    </v-fade-transition>

                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-carousel-item>
          </v-col>
        </v-carousel>


        <!-- ********************************************** -->
        <!-- Message d'erreur -->
        <!-- ********************************************** -->

        <v-row v-if="!formValid && !this.form.menuNavigationId" class="my-3">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2">
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon>
              {{ i18n.t("missingField") }}
            </div>
          </div>
        </v-row>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from "../../../../store/index";
import { mapState, mapWritableState } from "pinia";
import { mapActions } from "pinia";
import BackBtn from "../../utility/backBtn.vue";
import AlertBox from "../../../sfc/Alert.vue";
import googleApi from "@/plugins/google";
import storefront from "@/plugins/storefront";

export default {
  name: "ResidentialService",
  components: { icon, BackBtn, AlertBox },
  data() {
    return {
      i18n: i18n,
      slideshow: {
        serviceOptions: [
          {
            id: 1,
            name: "ResiWastePickUp",
            apiName: "WasteAndRecycling",
            text: i18n.t("wasteAndRecyclingPickup", "en"),
            textFr: i18n.t("wasteAndRecyclingPickup", "fr"),
            displayText: i18n.t("wasteAndRecyclingPickup"),
            icon: "ResiWastePickUp",
          },
          {
            id: 2,
            name: "ResiWastePickUp",
            apiName: "WasteRecyclingOrganics",
            text: i18n.t('wasteRecyclingAndOrganics', 'en'),
            textFr: i18n.t('wasteRecyclingAndOrganics', 'fr'),
            displayText: i18n.t("wasteRecyclingAndOrganics"),
            icon: "WasteRecyPickUp",
          },
          {
            id: 3,
            name: "ResiWastePickUp",
            apiName: "WasteOnly",
            text: i18n.t("wasteOnly", "en"),
            textFr: i18n.t("wasteOnly", "fr"),
            displayText: i18n.t("wasteOnly"),
            icon: "ResiWastePickUp",
          },
          {
            id: 4,
            name: "ResiWastePickUp",
            apiName: "RecyclingOnly",
            text: i18n.t("recyclingOnly", "en"),
            textFr: i18n.t("recyclingOnly", "fr"),
            displayText: i18n.t("recyclingOnly"),
            icon: "ResiWastePickUp",
          },
          {
            id: 5,
            name: "FoodWaste",
            apiName: "Organics",
            text: i18n.t("organics", "en"),
            textFr: i18n.t("organics", "fr"),
            displayText: i18n.t("organics"),
            icon: "OrganicWaste",
          },
          {
            id: 6,
            name: "YardWaste",
            apiName: "YardWaste",
            text: i18n.t("yardWaste", "en"),
            textFr: i18n.t("yardWaste", "fr"),
            displayText: i18n.t("yardWaste"),
            icon: "YardWaste",
          },
          {
            id: 7,
            name: "DumpsterRental",
            apiName: "DumpsterRental",
            text: i18n.t("dumpsterRental", "en"),
            textFr: i18n.t("dumpsterRental", "fr"),
            displayText: i18n.t("dumpsterRental"),
            icon: "DumpsterRental",
          },
          {
            id: 8,
            name: "ShreddingView",
            apiName: "Shredding",
            text: i18n.t("shredding", "en"),
            textFr: i18n.t("shredding", "fr"),
            displayText: i18n.t("shredding"),
            icon: "Shredding",
          },
          {
            id: 9,
            name: "BulkPickUp",
            apiName: "BulkItemPickup",
            text: i18n.t("bulkyItemPickup", "en"),
            textFr: i18n.t("bulkyItemPickup", "fr"),
            displayText: i18n.t("bulkyItemPickup"),
            icon: "BulkPickUp",
          },
          {
            id: 10,
            name: "MhoamResidential",
            apiName: "ApartmentsAndCondos",
            text: i18n.t("apartmentsCondosHOAs", "en"),
            textFr: i18n.t("apartmentsCondosHOAs", "fr"),
            displayText: i18n.t("apartmentsCondosHOAs"),
            icon: "MhoamView",
          },
          {
            id: 11,
            name: "MunicipalitiesView",
            apiName: "Municipalities",
            text: i18n.t("municipalities", "en"),
            textFr: i18n.t("municipalities", "fr"),
            displayText: i18n.t("municipalities"),
            icon: "Municipalities",
          },
          {
            id: 12,
            name: "PortableToilets",
            apiName: "PortableToilet",
            text: i18n.t("portableToilets", "en"),
            textFr: i18n.t("portableToilets", "fr"),
            displayText: i18n.t("portableToilets"),
            icon: "PortableToilets",
          },
          {
            id: 13,
            name: "SomethingElse",
            apiName: "SomethingElse",
            text: "Something Else?",
            displayText: i18n.t("somethingElse"),
            subtitle: "Send us a message",
            icon: "question",
          }
        ],
        defaultServiceOptions:
          [
            {
              id: 1,
              name: "ResiWastePickUp",
              apiName: "WasteAndRecycling",
              text: i18n.t("wasteAndRecyclingPickup", "en"),
              textFr: i18n.t("wasteAndRecyclingPickup", "fr"),
              displayText: i18n.t("wasteAndRecyclingPickup"),
              icon: "ResiWastePickUp",

            },
            {
              id: 2,
              name: "DumpsterRental",
              apiName: "DumpsterRental",
              text: i18n.t("dumpsterRental", "en"),
              textFr: i18n.t("dumpsterRental", "fr"),
              displayText: i18n.t("dumpsterRental"),
              icon: "DumpsterRental",
            },
            {
              id: 3,
              name: "MhoamResidential",
              apiName: "ApartmentsAndCondos",
              text: i18n.t("apartmentsCondosHOAs", "en"),
              textFr: i18n.t("apartmentsCondosHOAs", "fr"),
              displayText: i18n.t("apartmentsCondosHOAs"),
              icon: "MhoamView",
            },
            {
              id: 4,
              name: "MunicipalitiesView",
              apiName: "Municipalities",
              text: i18n.t("municipalities", "en"),
              textFr: i18n.t("municipalities", "fr"),
              displayText: i18n.t("municipalities"),
              icon: "Municipalities",
            },
            {
              id: 5,
              name: "SomethingElse",
              apiName: "SomethingElse",
              text: "Something Else?",
              displayText: i18n.t("somethingElse"),
              subtitle: "Send us a message",
              icon: "question",
            }
          ]
      },
    };
  },

  // * MOUNTED : Filtered service options based on the adresse
  mounted: function () {
    if (this.servicesofferedResi) {
      this.slideshow = this.filterSlideshowByServices(this.slideshow, this.servicesofferedResi);
    } else if (this.apiResultOriginal?.data?.RequestQuoteSetting?.Residential) {
      this.updateServiceOptions('Residential');
    } else {
      this.setDefaultServiceOptions();
    }
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "showGetAQuote",
      "showGoToStore",
      "headerstore",
      "servicesofferedResi",
      "apiResult",
      "apiResultOriginal",
    ]),
    ...mapWritableState(useIndexStore, [
      "formValid",
      "loading",
      "loadingWithService",
    ]),
    ...mapWritableState(useUserStore, ["form"]),
  },
  methods: {
    ...mapActions(useIndexStore, [
      "back",
      "updateStages",
      "updateViewComponent",
      "getLeadTypeFromServiceType",
      "containServicePackgesOf",
    ]),
    filterEnabledServices(settingType) {
      const settings = this.apiResultOriginal?.data?.RequestQuoteSetting?.[settingType] || {};
      return this.slideshow.serviceOptions.filter(service => settings[service.apiName]);
    },
    setDefaultServiceOptions() {
      this.slideshow.serviceOptions = this.slideshow.defaultServiceOptions;
    },
    filterSlideshowByServices(slideshow, services) {
      const seen = new Set(); // To track already added items

      return Object.fromEntries(
        Object.entries(slideshow).map(([key, items]) => [
          key,
          items.filter(item => {
            if (services.includes(item.name) && !seen.has(item.name)) {
              seen.add(item.name); // Add to seen to skip subsequent occurrences
              return true;
            }
            return false;
          })
        ])
      );
    },
    updateServiceOptions(settingType) {
      let filteredServices = this.filterEnabledServices(settingType);

      // Add "Something Else" if it's not already in the list
      const somethingElse = this.slideshow.serviceOptions.find(item => item.name === "SomethingElse");
      if (somethingElse && !filteredServices.includes(somethingElse)) {
        filteredServices.push(somethingElse);
      }

      // Update service options or set to default if empty
      this.slideshow.serviceOptions = filteredServices.length ? filteredServices : this.slideshow.defaultServiceOptions;
    },
    onChangeServiceList() {
      if (this.loadingWithService) return;

      const leadType = this.getLeadTypeFromServiceType(
        this.form.serviceType,
        this.form.categoryType
      ).toLowerCase();
      const gtagParams = [
        leadType,
        0,
        "Display Lead Field",
        process.env.VUE_APP_GTAG_LEAD_CATEGORY,
        this.getLeadTypeFromServiceType(
          this.form.serviceType,
          this.form.categoryType
        ),
        this.form.categoryType,
        this.apiResult.districtID,
      ];

      if (this.form.categoryType === "Apartments, Condos & HOAs") {
        googleApi.gTagSendEvents(...gtagParams);
        this.updateStages("Redirect Stage");
      } else if (this.showGetAQuote && this.showGoToStore) {
        googleApi.gTagSendEvents(...gtagParams);
        this.updateViewComponent("ResiStorefront");
        this.form.menuOrder.push("ResiStorefront");
        this.form.webLeadStep = "Redirect Stage";
      } else if (
        !this.showGetAQuote &&
        this.showGoToStore &&
        this.form.categoryType !== "Municipalities" &&
        this.form.categoryType !== "Organics" &&
        this.form.categoryType !== "Yard Waste"
      ) {
        this.loadingWithService = true;
        this.form.webLeadStep = "Redirect Stage";
        storefront.sendInfoWithService();
      } else {
        googleApi.gTagSendEvents(...gtagParams);
        this.updateStages("Redirect Stage");
      }
    },
  },
};
</script>

<style scoped>
.min-height-44 {
  min-height: 44px;
}
</style>
